import React, { FC } from 'react';
import { Container, Navigation, LogoLink, NavigationAnchor } from './style';
import LogoSVG from '~/assets/layout/logo.svg';
import siteMetadata from '~/config/metadata';

type HeaderProps = {
  inHome: boolean;
};

const Header: FC<HeaderProps> = ({ inHome }) => {
  return (
    <Container inHome={inHome}>
      <Navigation>
        <LogoLink to="/" title="página inicial">
          <LogoSVG />
          <span>Aquele Hugo</span>
        </LogoLink>

        <ul>
          {/* <li>
            <NavigationLink to="/blog">blog</NavigationLink>
          </li> */}
          <li>
            <NavigationAnchor href={siteMetadata.links.youtubeMusicChannel}>
              música
            </NavigationAnchor>
          </li>
          {/* <li>
            <NavigationLink to="/sobre">sobre</NavigationLink>
          </li> */}
        </ul>
      </Navigation>
    </Container>
  );
};

export default Header;
