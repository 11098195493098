const siteMetadata = {
  defaultTitle: 'Música e desenvolvimento web',
  titleTemplate: 'Aquele Hugo | %s',
  siteUrl: 'https://aquelehugo.com',
  defaultDescription: 'Um lugar para compartilhar conhecimento e música.',
  twitterUsername: 'aquelehugo',
  links: {
    youtubeMusicChannel: 'https://www.youtube.com/c/aquelecanaldohugo',
  },
};

export default siteMetadata;
