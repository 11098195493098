import React, { FC } from 'react';
import Header from '../Header';
import GlobalStyle from '~/styles/global';
import { MainContent } from './style';
import SEO from '../SEO';

type LayoutProps = {
  inHome?: boolean;
};

const Layout: FC<LayoutProps> = ({ inHome = false, children }) => (
  <>
    <SEO />
    <Header inHome={inHome} />
    <MainContent>{children}</MainContent>
    <GlobalStyle />
  </>
);

export default Layout;
