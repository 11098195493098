import { css } from 'styled-components';
import { MediaQuery } from './layout';

export const FontFamily = {
  OSWALD: 'Oswald, Helvetica, Arial, sans-serif',
  RALEWAY: 'Raleway, Helvetica, Arial, sans-serif',
} as const;

export const FontWeight = {
  EXTRA_LIGHT: '200',
  LIGHT: '300',
  REGULAR: '400',
  MEDIUM: '500',
  SEMI_BOLD: '600',
} as const;

export const BODY = css`
  font-family: ${FontFamily.RALEWAY};
  font-weight: ${FontWeight.REGULAR};
  font-size: 16px;
  line-height: 24px;

  @media ${MediaQuery.TABLET_UP} {
    font-size: 20px;
    line-height: 32px;
  }
`;

export const CAPTION = css`
  font-family: ${FontFamily.RALEWAY};
  font-weight: ${FontWeight.REGULAR};
  font-size: 13.33px;
  line-height: 20px;

  @media ${MediaQuery.TABLET_UP} {
    font-size: 15px;
    line-height: 24px;
  }
`;

export const H1 = css`
  font-family: ${FontFamily.OSWALD};
  font-size: 39.81px;
  font-weight: ${FontWeight.MEDIUM};
  line-height: 48px;

  @media ${MediaQuery.TABLET_UP} {
    font-size: 84.17px;
    line-height: 96px;
  }
`;

export const H2 = css`
  font-family: ${FontFamily.OSWALD};
  font-size: 33.18px;
  font-weight: ${FontWeight.REGULAR};
  line-height: 40px;

  @media ${MediaQuery.TABLET_UP} {
    font-size: 63.15px;
    line-height: 72px;
  }
`;

export const H2_HOME = css`
  ${H2}

  font-weight: ${FontWeight.LIGHT};

  @media ${MediaQuery.TABLET_UP} {
    font-weight: ${FontWeight.EXTRA_LIGHT};
  }
`;

export const H4 = css`
  font-family: ${FontFamily.OSWALD};
  font-size: 23.04px;
  font-weight: ${FontWeight.LIGHT};
  line-height: 28px;

  @media ${MediaQuery.TABLET_UP} {
    line-height: 40px;
    font-size: 35.54px;
  }
`;

export const H5 = css`
  font-family: ${FontFamily.OSWALD};
  font-size: 19.2px;
  font-weight: ${FontWeight.MEDIUM};
  line-height: 24px;

  @media ${MediaQuery.TABLET_UP} {
    font-size: 26.66px;
    line-height: 32px;
  }
`;

export const H5_LIGHT = css`
  ${H5}
  font-weight: ${FontWeight.LIGHT};
`;
