import styled from 'styled-components';
import { Color } from '~/styles/colors';
import { GatsbyLinkProps, Link } from 'gatsby';

import { H4, H5_LIGHT } from '~/styles/typography';
import { MediaQuery } from '~/styles/layout';

export const HEADER_HEIGHT = 80;

type ContainerProps = {
  inHome: boolean;
};

export const Container = styled.header<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  background: ${({ inHome }) => (inHome ? Color.BRAND : 'none')};
`;

export const Navigation = styled.nav`
  display: flex;
  margin: 0 16px;
  height: 100%;
  width: 100%;
  border-bottom: 1px solid ${Color.FOREGROUND};
  align-items: center;
  padding: 16px;

  ul {
    list-style: none;
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  li {
    margin-left: 16px;
  }

  @media ${MediaQuery.TABLET_UP} {
    min-width: 768px;
    width: 70%;
  }
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;

  > svg {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    transition: 0.3s ease-in-out;
  }

  :hover > svg {
    transition: 0.3s ease-out;
    transform: rotate(360deg);
  }

  > span {
    @media ${MediaQuery.MOBILE} {
      display: none;
    }

    color: ${Color.FOREGROUND};
    ${H4}
    line-height: 48px;
  }
`;

export const NavigationAnchor = styled.a`
  &:first-child {
    margin-left: auto;
  }

  ${H5_LIGHT}
  color: ${Color.FOREGROUND};
`;

export const NavigationLink = styled<React.FC<GatsbyLinkProps<{}>>>(
  NavigationAnchor
).attrs(() => ({
  as: Link,
}))``;
