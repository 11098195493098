import { createGlobalStyle } from 'styled-components';
import { Color } from './colors';
import { MediaQuery } from './layout';
import { BODY, H1, H2, FontWeight, CAPTION } from './typography';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
  }

  a {
    text-decoration: none;
    font-weight: ${FontWeight.SEMI_BOLD};

    color: ${Color.LINK};
  }

  body {
    background: ${Color.BODY_BG};
    color: ${Color.FOREGROUND};
    overflow-x: hidden;
    ${BODY}
  }

  caption {
    ${CAPTION}
  }

  h1, h2, h3, h4, h5, h6 {
    text-align: center;
    margin-bottom: 24px;

    @media ${MediaQuery.TABLET_UP} {
      margin-bottom: 32px;
    }
  }

  h1 {
    ${H1}
  }

  h2 {
    ${H2}
  }
`;

export default GlobalStyle;
